import { useState, useEffect, Fragment, forwardRef } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import { TransitionProps } from "@mui/material/transitions";
import { inflectionAdults, inflectionKids, inflectionKidsAges, parseIntsToKids } from "../utils/inflection";
import { createNumberRangeArray, removeNullUndefined } from "../utils/main";
import { getParam } from "../utils/url";

export interface SearchFormPersonsType {
    childrenAges: HTMLInputElement;
    AdultsCount: HTMLInputElement;
}
export interface PersonsGroupValues {
    adults: {
        max: number;
        actual: number;
    };
    kids: {
        max: number;
        actual: number;
        dates: number[];
    };
}
const inputs = (
    childrenAges: number[],
    adultsCount: number,
    childrensInput: HTMLInputElement,
    adultsInput: HTMLInputElement,
    childrensCount: number
) => {
    // Naplňuje mw:Inputy hodnotami z persons group formuláře
    childrensInput.value = removeNullUndefined(childrenAges, childrensCount).join("-");
    adultsInput.value = adultsCount.toString();
};
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const SearchFormPersons = (props: SearchFormPersonsType) => {
    // Componenta na persons group
    const URL = {
        adultsCount: getParam("AdultsCount"),
        childrenAges: getParam("ChildrenAges")
    }
    const [error, setError] = useState<{name: string, status: boolean, message: string}>({
        name: '',
        status: false,
        message: ''
    })
    const [open, setOpen] = useState<boolean>(false); // true / false jestli je modal otevřený nebo ne
    const [values, setValues] = useState<PersonsGroupValues>({
        // hodnoty sesbírané z persons group formuláře
        adults: {
            max: 8,
            actual: URL.adultsCount ? parseInt(URL.adultsCount) : 2,
        },
        kids: {
            max: 3,
            actual: URL.childrenAges ? parseIntsToKids(URL.childrenAges).length : 0,
            dates: URL.childrenAges ? parseIntsToKids(URL.childrenAges) : [],
        },
    });
    const handleModal = () => {
        if(values.kids.actual === values.kids.dates.length) {
            // Přepína otevření / zavřerní modalu
            setOpen(!open);
        } else {
            setError({
                name: 'Nevyplněné věky',
                status: true,
                message: 'Nie dopełniliście wieku dzieci.'
            })
        }
    };
    const reserError = () => {
        setError({
            name: '',
            status: false,
            message: ''
        })
    }
    const clearData = () => {
        // clearuje data formulárě na default hodnoty
        setValues({
            ...values,
            adults: {
                ...values.adults,
                actual: 1,
            },
            kids: {
                ...values.kids,
                actual: 0,
                dates: [],
            },
        });
    };
    useEffect(() => {
        setValues({
            ...values,
            kids: {
                ...values.kids,
                dates: removeNullUndefined(values.kids.dates, values.kids.actual)
            }
        })
        // po změne stavu "values" se data nalévají do inputů
        inputs(values.kids.dates, values.adults.actual, props.childrenAges, props.AdultsCount, values.kids.actual);
    }, [props.AdultsCount, props.childrenAges, values]);
    return (
        <div className="ss-search-item ss-search-item--modal btn-group bootstrap-select show-tick">
            <button onClick={handleModal} type="button" className="btn dropdown-toggle bs-placeholder btn-default">
                <span className="filter-option pull-left">
                    {inflectionAdults(values.adults.actual)}, {inflectionKids(values.kids.actual)}
                </span>
                <FamilyRestroomIcon color="primary" tw="text-3xl" />
            </button>
            <Dialog open={open} onClose={handleModal} TransitionComponent={Transition} fullWidth={true} maxWidth={"sm"}>
                <Box className="dialog-header">
                    <DialogTitle className="modal-title ss-text-h3 ss-text-h3__color-1">
                        <FamilyRestroomIcon color="secondary" tw="text-4xl" />
                        <span tw="pl-4">Uczestnicy wyjazdu</span>
                    </DialogTitle>
                    <DialogTitle onClick={handleModal}>
                        <button type="button" className="close js-modal-clear" data-dismiss="modal">
                            <span aria-hidden="true">×</span>
                        </button>
                    </DialogTitle>
                </Box>
                <DialogContent className="flex-row gap no-overflow">
                    <Box className="flex-column">
                        <label>Liczba osób dorosłych</label>
                        <div className="btn-group bootstrap-select">
                            <button
                                className="btn dropdown-toggle btn-default"
                                data-toggle="dropdown"
                                title={values.adults.actual.toString()}
                            >
                                <span className="filter-option pull-left">{values.adults.actual}</span>
                                <span className="bs-caret">
                                    <span className="caret"></span>
                                </span>
                            </button>
                            <div className="dropdown-menu open">
                                <ul className="dropdown-menu inner">
                                    {createNumberRangeArray(values.adults.max).map((item, index: number) => (
                                        <li
                                            key={index}
                                            className={item === values.adults.actual ? "selected" : ""}
                                            onClick={() =>
                                                setValues({
                                                    ...values,
                                                    adults: {
                                                        ...values.adults,
                                                        actual: item,
                                                    },
                                                })
                                            }
                                        >
                                            <a>
                                                <span className="text">{item}</span>
                                                <span className="glyphicon glyphicon-ok check-mark"></span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Box>
                    <Box className="flex-column">
                        <label>Liczba dzieci</label>
                        <div className="btn-group bootstrap-select">
                            <button
                                className="btn dropdown-toggle btn-default"
                                data-toggle="dropdown"
                                title={values.kids.actual.toString()}
                            >
                                <span className="filter-option pull-left">{values.kids.actual}</span>
                                <span className="bs-caret">
                                    <span className="caret"></span>
                                </span>
                            </button>
                            <div className="dropdown-menu open">
                                <ul className="dropdown-menu inner">
                                    <li
                                        className={values.kids.actual === 0 ? "selected" : ""}
                                        onClick={() =>
                                            setValues({
                                                ...values,
                                                kids: {
                                                    ...values.kids,
                                                    actual: 0,
                                                },
                                            })
                                        }
                                    >
                                        <a>
                                            <span className="text">0</span>
                                            <span className="glyphicon glyphicon-ok check-mark"></span>
                                        </a>
                                    </li>
                                    {createNumberRangeArray(values.kids.max).map((item, index: number) => (
                                        <li
                                            key={index}
                                            className={item === values.kids.actual ? "selected" : ""}
                                            onClick={() =>
                                                setValues({
                                                    ...values,
                                                    kids: {
                                                        ...values.kids,
                                                        actual: item,
                                                    },
                                                })
                                            }
                                        >
                                            <a>
                                                <span className="text">{item}</span>
                                                <span className="glyphicon glyphicon-ok check-mark"></span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
                {values.kids.actual !== 0 ? (
                    <Fragment>
                        <Box className="border-bottom" />
                        <DialogTitle>Data urodzenia</DialogTitle>
                        <DialogContent className="flex-row gap">
                            {createNumberRangeArray(values.kids.actual).map((_item, index: number) => (
                                <Box className="flex-column" key={index}>
                                    <label>{index + 1} dziecko</label>
                                    <div className="btn-group bootstrap-select">
                                        <button
                                            className="btn dropdown-toggle btn-default"
                                            data-toggle="dropdown"
                                            title={`${index + 1} dziecko`}
                                        >
                                            <span className="filter-option pull-left">
                                                {values.kids.dates[index] !== undefined
                                                    ? inflectionKidsAges(values.kids.dates[index])
                                                    : "Wybierać"}
                                            </span>
                                            <span className="bs-caret">
                                                <span className="caret"></span>
                                            </span>
                                        </button>
                                        <div className="dropdown-menu open">
                                            <ul className="dropdown-menu inner">
                                                {createNumberRangeArray(18).map((item, optionIndex: number) => {
                                                    return (
                                                        <li
                                                            key={optionIndex}
                                                            className={item === values.kids.actual ? "selected" : ""}
                                                            onClick={() => {
                                                                let vals: number[] = [...values.kids.dates];
                                                                vals[index] = optionIndex;
                                                                setValues({
                                                                    ...values,
                                                                    kids: {
                                                                        ...values.kids,
                                                                        dates: vals,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <a>
                                                                <span className="text">
                                                                    {inflectionKidsAges(optionIndex)}
                                                                </span>
                                                                <span className="glyphicon glyphicon-ok check-mark"></span>
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Box>
                            ))}
                        </DialogContent>
                    </Fragment>
                ) : null}
                {error.status ? (
                    <DialogContent>
                        <div className="alert alert-danger">
                            {error.message}
                        </div>
                    </DialogContent>
                ) : null}
                <DialogActions>
                    <button
                        type="button"
                        className="ss-btn-full ss-btn-full__color-3 pull-right darker-btn"
                        onClick={clearData}
                    >
                        Wyczyść dane
                    </button>
                    <button
                        type="button"
                        className="ss-btn-full ss-btn-full__color-3 pull-right darker-btn"
                        onClick={handleModal}
                    >
                        Zapisz
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default SearchFormPersons;
