const removeNullUndefined = (array: number[], maxItems: number) => {
    // Odstraňuje "null" a "undefined" hodnoty z array
    let arr:number[] = []
    array.forEach((item: number, index: number) => {
        if(index < maxItems) {
            arr.push(item)
        }
    })
    return arr.filter((item) => item !== null && item !== undefined);
};
const createNumberRangeArray = (number: number) => {
    // Vytvoří array s číslama "createNumberRangeArray(3) returns [1,2,3]"
    let arr = [];
    for (let i = 1; i < number + 1; i++) {
        arr.push(i);
    }
    return arr;
};
export {
    removeNullUndefined,
    createNumberRangeArray,
}