import React from "react";
import { createRoot, Root } from "react-dom/client";
import SearchFormPersons, { SearchFormPersonsType } from "@magicware/search-form-persons/components/SearchFormPersons";
import { ThemeProvider } from "@emotion/react";
import theme from "./styles/muiTheme";

let currentElement: HTMLElement | undefined;
let root: Root | undefined;

export interface NomadeRenderOptions extends SearchFormPersonsType {
    targetElement: HTMLElement;
}

export interface NomadePublicMethods {
    render: (options: NomadeRenderOptions) => void;
}

const publicMethods: NomadePublicMethods = {
    render: (options) => {
        if (root) root.unmount();
        if (currentElement) currentElement.innerHTML = "";
        currentElement = options.targetElement;
        root = createRoot(currentElement);
        root.render(
            <ThemeProvider theme={theme}>
                <React.StrictMode>
                    <SearchFormPersons childrenAges={options.childrenAges} AdultsCount={options.AdultsCount} />
                </React.StrictMode>
            </ThemeProvider>
        );
    },
};

const windowPublicMethods = (window as any).nomade ?? ((window as any).nomade = {});
Object.assign(windowPublicMethods, publicMethods);
