const inflectionKidsAges = (index: number): string => { // Vytvoří string skloňování let / věků
    if (index === 0) {
        return "do 1 roku";
    } else if (index === 1) {
        return "1 wiek";
    } else if (index > 1 && index < 5) {
        return `${index} lata`;
    } else {
        return `${index} lat`;
    }
};
const inflectionKids = (index: number): string => { // Vytvoří string skloňování dospělých
    if (index === 1) {
        return "1 dziecko";
    } else {
        return `${index} dzieci`;
    }
};
const inflectionAdults = (index: number): string => { // Vytvoří string skloňování dětí
    if (index === 1) {
        return "1 osoba dorosła";
    } else if (index > 1 && index < 5) {
        return `${index} osoby dorosłe`;
    } else if (index > 4 && index < 7) {
        return `${index} osób dorosłych`;
    } else {
        return `${index} dorosłych`;
    }
};
const parseIntsToKids = (childrenAges: string): number[] => {
    const agesTextArray:number[] = []
    childrenAges.split("-").forEach((item: string): void => {
        agesTextArray.push(parseInt(item))
    })
    return agesTextArray;
}
export {
    inflectionKidsAges,
    inflectionAdults,
    inflectionKids,
    parseIntsToKids
}